var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "n-page",
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { lg: 12, md: 24, sm: 24, xs: 24 } },
            [
              _c(
                "a-card",
                {
                  staticClass: "gx-card-widget title-heading",
                  attrs: { title: "My Profile Settings" },
                },
                [
                  _c(
                    "a-form",
                    { attrs: { form: _vm.form, layout: "vertical" } },
                    [
                      _c(
                        "a-form-item",
                        [
                          _c(
                            "a-upload",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: ["upload"],
                                  expression: "['upload']",
                                },
                              ],
                              attrs: {
                                accept: "image/png,image/jpeg/image/jpg",
                                name: "logo",
                                action:
                                  "http://127.0.0.1:8000/api/member/upload-avatar",
                                headers: { Authorization: _vm.userToken },
                                "list-type": "picture",
                                "show-upload-list": false,
                              },
                              on: { change: _vm.uploadImage },
                            },
                            [
                              _c(
                                "a-button",
                                [
                                  _c("a-icon", { attrs: { type: "upload" } }),
                                  _vm._v(
                                    " Change your profile picture\n              "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "user_info" },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "First Name" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "first_name",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message: "First name is required.",
                                          },
                                        ],
                                      },
                                    ],
                                    expression:
                                      "[\n                  'first_name',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'First name is required.',\n                      },\n                    ],\n                  },\n                ]",
                                  },
                                ],
                                attrs: { type: "text" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "Last Name" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "last_name",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message:
                                              "Last name is a required field.",
                                          },
                                        ],
                                      },
                                    ],
                                    expression:
                                      "[\n                  'last_name',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Last name is a required field.',\n                      },\n                    ],\n                  },\n                ]",
                                  },
                                ],
                                attrs: { type: "text" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "user_info" },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "Email" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "work_email",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message: "Your email is required.",
                                          },
                                        ],
                                      },
                                    ],
                                    expression:
                                      "[\n                  'work_email',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Your email is required.',\n                      },\n                    ],\n                  },\n                ]",
                                  },
                                ],
                                attrs: { type: "text" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "Phone Number" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: ["phone"],
                                    expression: "['phone']",
                                  },
                                ],
                                attrs: { type: "text" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "Date of Birth" } },
                            [
                              _c("a-date-picker", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: ["dob"],
                                    expression: "['dob']",
                                  },
                                ],
                                staticStyle: { width: "100%" },
                                attrs: { format: "DD/MM/YYYY" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "user_info" },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "Emergency Name" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: ["emergency_name"],
                                    expression: "['emergency_name']",
                                  },
                                ],
                                attrs: { type: "text" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "Emergency Phone" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: ["emergency_phone"],
                                    expression: "['emergency_phone']",
                                  },
                                ],
                                attrs: { type: "text" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "user_info" },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "Address 1" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: ["address_1"],
                                    expression: "['address_1']",
                                  },
                                ],
                                attrs: { type: "text" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "Address 2" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: ["address_2"],
                                    expression: "['address_2']",
                                  },
                                ],
                                attrs: { type: "text" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "user_info" },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "Town" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: ["town"],
                                    expression: "['town']",
                                  },
                                ],
                                attrs: { type: "text" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "Region" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: ["region"],
                                    expression: "['region']",
                                  },
                                ],
                                attrs: { type: "text" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "Postcode" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: ["post_code"],
                                    expression: "['post_code']",
                                  },
                                ],
                                attrs: { type: "text" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "ant-row ant-form-item" }, [
                        _c("div", { staticClass: "ant-form-item-label" }, [
                          _c(
                            "label",
                            { attrs: { for: "region", title: "Region" } },
                            [_vm._v("Country")]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "ant-form-item-control-wrapper" },
                          [
                            _c("country-select", {
                              attrs: {
                                country: _vm.fields.country,
                                "top-country": "IE",
                                "class-name": "ant-input",
                              },
                              model: {
                                value: _vm.fields.country,
                                callback: function ($$v) {
                                  _vm.$set(_vm.fields, "country", $$v)
                                },
                                expression: "fields.country",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "a-form-item",
                        { staticClass: "gx-text-right" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", "html-type": "submit" },
                              on: { click: _vm.handleForm },
                            },
                            [
                              _vm._v(
                                "\n              Save Details\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { lg: 12, md: 24, sm: 24, xs: 24 } },
            [
              _c(
                "a-card",
                {
                  staticClass: "gx-card-widget",
                  attrs: { title: "Child Account (Under 16)" },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "extra" }, slot: "extra" },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { height: "35px" },
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.openSubAccountModal(null)
                            },
                          },
                        },
                        [_vm._v("\n            New Sub Account\n          ")]
                      ),
                    ],
                    1
                  ),
                  _vm.renderComponent
                    ? _c("sub-accounts-table", {
                        on: { manage: _vm.openSubAccountModal },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.subManagerVisible
        ? _c("sub-accounts-manage-modal", {
            attrs: {
              "user-id": _vm.selectedUserId,
              visible: _vm.subManagerVisible,
            },
            on: { close: _vm.closeSubAccountModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }